import React from 'react'

const PC = () => {
  return (
    <div className='xl:pt-[146px] md:pt-[136px] sm:pt-[92px] pt-[92px] px-2 sm:px-16 md:px-20 xl:px-40'>
    <div className='roboto-bold'>Политика обработки персональных данных.</div><br />
    <div className='roboto-bold'>1. Общие положения</div>
    1.1 ИП Самарчев Николай Алексеевич ОГРНИП 323645700119062 (далее по тексту – Оператор)
    ставит соблюдение прав и свобод граждан одним из важнейших условий осуществления
    своей деятельности.<br/>
    1.2 Политика Оператора в отношении обработки персональных данных (далее по тексту —
    Политика) применяется ко всей информации, которую Оператор может получить о
    посетителях веб-сайта www.jurix.pro. Персональные данные обрабатывается в соответствии с
    ФЗ «О персональных данных» № 152-ФЗ.<br/><br/>
    <div className='roboto-bold'>2. Основные понятия, используемые в Политике:</div>
    2.1 Веб-сайт - совокупность графических и информационных материалов, а также программ
    для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу
    www.jurix.pro;<br/>
    2.2 Пользователь – любой посетитель веб-сайта www.jurix.pro;
    2.3 Персональные данные – любая информация, относящаяся к Пользователю веб-сайта
    www.jurix.pro;<br/>
    2.4 Обработка персональных данных - любое действие с персональными данными,
    совершаемые с использованием ЭВМ, равно как и без их использования;<br/>
    2.5 Обезличивание персональных данных – действия, результатом которых является
    невозможность без использования дополнительной информации определить
    принадлежность персональных данных конкретному Пользователю или лицу;<br/>
    2.6 Распространение персональных данных – любые действия, результатом которых является
    раскрытие персональных данных неопределенному кругу лиц;<br/>
    2.7 Предоставление персональных данных – любые действия, результатом которых является
    раскрытие персональных данных определенному кругу лиц;<br/>
    2.8 Уничтожение персональных данных – любые действия, результатом которых является
    безвозвратное уничтожение персональных на ЭВМ или любых других носителях.<br/><br/>
    <div className='roboto-bold'>3. Оператор может обрабатывать следующие персональные данные Пользователя:</div>
    3.1 Список персональных данных, которые обрабатывает оператор: фамилия, имя, отчество,
    номер телефона, адрес электронной почты.<br/>
    3.2. Кроме того, на сайте происходит сбор и обработка обезличенных данных о посетителях
    (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика, Гугл
    Аналитика и других).<br/><br/>
    <div className='roboto-bold'>4. Цели обработки персональных данных</div>
    4.1 Персональные данные пользователя - фамилия, имя, отчество, номер телефона, адрес
    электронной почты - обрабатываются со следующей целью: запись на прием к юристу.
    Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах,
    специальных предложениях и различных событиях. Пользователь всегда может отказаться от
    получения информационных сообщений, направив Оператору письмо на адрес pd@jurix.pro<br/>
    4.2 Обезличенные данные Пользователей, собираемые с помощью сервисов интернетстатистики, служат для сбора информации о действиях Пользователей на сайте, улучшения
    качества сайта и его содержания.<br/><br/>
    <div className='roboto-bold'>5. Правовые основания обработки персональных данных</div>
    5.1 Оператор обрабатывает персональные данные Пользователя только в случае их отправки
    Пользователем через формы, расположенные на веб-сайте www.jurix.pro. Отправляя свои
    персональные данные Оператору, Пользователь выражает свое согласие с данной
    Политикой.<br/>
    5.2 Оператор обрабатывает обезличенные данные о Пользователе в случае, если
    Пользователь разрешил это в настройках браузера (включено сохранение файлов «cookie» и
    использование технологии JavaScript).<br/><br/>
    <div className='roboto-bold'>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</div>
    6.1 Оператор обеспечивает сохранность персональных данных и принимает все возможные
    меры, исключающие доступ к персональным данным неуполномоченных лиц.<br/>
    6.2 Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
    третьим лицам, за исключением случаев, связанных с исполнением действующего
    законодательства.<br/>
    6.3. В случае выявления неточностей в персональных данных, Пользователь может
    актуализировать их, направив Оператору уведомление с помощью электронной почты на
    электронный адрес Оператора pd@jurix.pro, либо на почтовый адрес Оператора 410019, РФ,
    Саратов, ул. Танкистов, д. 37, оф. 2А-16, с пометкой «Актуализация персональных данных».<br/>
    6.3 Срок обработки персональных данных является неограниченным. Пользователь может в
    любой момент отозвать свое согласие на обработку персональных данных, направив
    Оператору уведомление с помощью электронной почты на электронный адрес Оператора
    pd@jurix.pro, либо на почтовый адрес Оператора 410019, РФ, Саратов, ул. Танкистов, д. 37, оф.
    2А-16, с пометкой «Отзыв согласия на обработку персональных данных».<br/><br/>
    <div className='roboto-bold'>7. Заключительные положения</div>
    7.1. Пользователь может получить любые разъяснения по интересующим вопросам,
    касающимся обработки его персональных данных, обратившись к Оператору с помощью
    электронной почты pd@jurix.pro, либо на почтовый адрес Оператора 410019, РФ, Саратов, ул.
    Танкистов, д. 37, оф. 2А-16.<br/>
    7.2. В данном документе будут отражены любые изменения политики обработки
    персональных данных Оператором. В случае существенных изменений Пользователю может
    быть выслана информация на указанный им электронный адрес.<br/>
    </div>
  )
}

export default PC