import React from 'react'
import Circle from '../../../../src/files/ServicesList/circle.png'

const Why = () => {
    const services = [
        {
            id:1,
            name: 'КОМАНДА ПРОФЕССИОНАЛОВ',
            description: 'Средний юридический наших юристов стаж более 10 лет. Специализируемся на сопровождении бизнеса, а также в области гражданского, административного, арбитражного, земельного и трудового права.',
        },
        {
            id:2,
            name: 'КОМПЛЕКСНОЕ ОБСЛУЖИВАНИЕ',
            description: 'Помогаем решить все возникающие вопросы – юридические, налоговые, бухгалтерские и кадровые. Работаем в офисе, на территории клиента и удаленно.',
        },
        {
            id:3,
            name: 'СОХРАНЯЕМ ВАШИ ВРЕМЯ И ДЕНЬГИ',
            description: 'На основе нашего опыта предложим оптимальный вариант решения Ваших проблем и оперативно приступим к работе в день обращения.',
        },
        {
            id:4,
            name: '25+ ЛЕТ',
            description: 'общей юридической практики, в команде выпускники лучших юридических ВУЗов России, руководитель – кандидат юридических наук.',
        },
        {
            id:5,
            name: '100+',
            description: 'благодарных клиентов каждый месяц получают решение своих проблем.',
        },
        {
            id:6,
            name: '80%',
            description: 'клиентов становятся постоянными клиентами и рекомендуют нас друзьям и партнерам.',
        }
    ]

  return (
    <div id='why' className='flex py-[120px] justify-center items-center flex-col px-2 sm:px-16 md:px-20 xl:px-40'>
        <div className='text-center roboto-bold text-[#232233] text-[32px]'>
            ПОЧЕМУ МЫ
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-3 mt-[60px] gap-4'>
            {
                services && services.map((el,index) => (
                    <div key={index} className='gap-[18px] relative shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] transition-all duration-300 hover:scale-[1.01] flex p-6 rounded-md bg-white cursor-pointer'>
                        <div className='h-[100%]'>
                            <img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>
                        </div>
                        <div className='flex flex-col gap-[12px]'>
                            <div className='roboto-medium text-[20px] text-[#232233]'>
                                {
                                    el.name
                                }
                            </div>
                            <div className='text-[16px] max-w-[491px] text-[#6C6C72]'>
                                {
                                    el.description
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Why