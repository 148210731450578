import { FaPhoneAlt, FaTelegramPlane, FaWhatsapp } from "react-icons/fa"

const Contacts = () => {


    const contact = [
        {
            title: <FaPhoneAlt size={26} color="#5956E9" />,
            contact: "8-800-222-4352",
            href: "tel:+78002224352",
            button: "Позвонить"
        },{
            title: <FaWhatsapp size={26} color="#28D146" />,
            contact: "7-929-773-4352",
            href: "https://wa.me/79297734352?text=Здравствуйте%2C+у+меня+есть+вопрос",
            button: "Написать"
        },{
            title: <FaTelegramPlane size={26} color="#5956E9" />,
            contact: "@jurix_pro",
            href: "https://telegram.me/jurix_pro",
            button: "Перейти"
        }
    ]

  return (
    <div className="xl:pt-[146px] w-[100dvw] md:pt-[136px] sm:pt-[92px] pt-[92px] px-2 sm:px-16 md:px-20 xl:px-40">
      <div className="w-[100%] pt-[20px] text-[24px] font-bold">
        Контакты
      </div>
      <div className="pt-[50px] gap-[5px] flex-wrap min-h-[57.3dvh] max-h-max pb-[50px] flex justify-center">
        {
            contact && contact.map((el, index) => {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <a href={el.href}>
                                <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                    {
                                        el.button
                                    }
                                </button>
                            </a>
                        </div>
                    )
                } else {
                    if(el.button === "Позвонить"){
                        return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                {
                                    el.button
                                }
                            </button>
                        </div>
                        )
                    }
                    return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <a href={el.href}>
                                <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                    {
                                        el.button
                                    }
                                </button>
                            </a>
                        </div>
                    )
                }
            })
        }
      </div>
    </div>
  )
}

export default Contacts
