import React, { useState } from 'react'
import Circle from '../../../../src/files/ServicesList/circle.png'
import Popup from '../Popup/Popup'

const ServicesList = () => {

    const [openPopup, setOpenPopup] = useState(null);

    const services = [
        {
            id:4,
            name: 'Юридическое сопровождение бизнеса',
            description: 'Комплексное решение юридических, бухгалтерских и кадровых вопросов от разработки документов до представления интересов в суде, анализ контрагентов и персонала на негатив',
            href: "/services/uridicheskoe-coprovozdenie-biznesa"        
        },
        {
            id:3,
            name: 'Защита продавцов на маркетплейсах',
            description: 'Работа с жалобами и претензиями покупателей, защита от «потребительского экстремизма», охрана интеллектуальной собственности в т.ч. карточек товара, инфографики, депонирование',
            href: "/services/zashita-prodavcov-na-marketplacah"
        },
        {
            id:1,
            name: 'Защита прав потребителей',
            description: 'Возврат некачественного товара, претензионная и исковая работа с продавцами и производителями, взыскание',
            href: "#zashita-prav-potrebitelei"
        },
        {
            id:2,
            name: 'Подготовка и анализ документов',
            description: 'Корректировка или разработка «с нуля» любых документов: юридических, кадровых, административных, анализ нарушений и «слабых» мест',
            href: "#podgotovka-i-analiz-documentov"
        },
        {
            id:5,
            name: 'Сделки с недвижимостью',
            description: 'Сопровождение сделок, подготовка и анализ документов, проверка объектов недвижимости на юридическую чистоту',
            href: "#sdelki-s-nedvizemostiu"
        },
        {
            id:6,
            name: 'Взыскание ущерба',
            description: 'Возмещение ущерба в результате ДТП, пожара, затопления и других происшествий',
            href: "#vziskanie-usherba"
        },
        {
            id:7,
            name: 'Семейные споры',
            description: 'Процедура развода, взыскание алиментов, раздел имущества, установление порядка общения с детьми',
            href: "#semeinie-spori"
        },
        {
            id:8,
            name: 'Работа с франшизами',
            description: 'Защита интересов франчайзи, претензионная и исковая работа с франшизой, взыскание паушального взноса',
            href: "#rabota-c-franshizami"
        }
    ]

    function openService(id){
        if(openPopup === null) {
            setOpenPopup(id)
        }
    }

    function redirect(href) {
        window.location.href=`${href}`
    }

    function renderPopup(){
        if(openPopup !== null){
            return (
                <Popup services={services} setOpenPopup={setOpenPopup} openPopup={openPopup} />
            )
        }
        return ''
    }

  return (
    <div id='services' className='flex py-[120px] flex-col px-2 sm:px-16 md:px-20 xl:px-40'>
        {/* <div className='text-center roboto-bold text-[#232233] text-[32px]'>
            ЧТО МЫ ПРЕДСТАВЛЯЕМ
        </div> */}
        {/* {
            renderPopup()
        } */}
        <div className='grid grid-cols-1 md:grid-cols-2 mt-[60px] gap-4'>
            {
                services && services.map((el,index) => (
                    <div onClick={() => redirect(el.href)} key={index} className='gap-[18px] pb-[70px] relative shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] transition-all duration-300 hover:scale-[1.01] flex p-6 rounded-md bg-white cursor-pointer'>
                        <div className='h-[100%]'>
                            <img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>
                        </div>
                        <div className='flex flex-col gap-[12px]'>
                            <div className='roboto-medium text-[20px] text-[#232233]'>
                                {
                                    el.name
                                }
                            </div>
                            <div className='text-[16px] max-w-[491px] text-[#6C6C72]'>
                                {
                                    el.description
                                }
                            </div>
                        </div>
                        <button className="w-max p-3 bg-[#5956E9] text-white roboto-bold rounded-md absolute right-[5px] bottom-[5px]">
                            Подробнее
                        </button>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default ServicesList