import React from 'react'
import Services from './Pages/Services/Services'
import './src/style/index.css'
import Header from './Component/Header/Header'
import Footer from './Component/Footer/Footer'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PC from './Pages/PC/PC'
import Contacts from './Pages/Contacts/Contacts'
import Service from './Pages/service/Service'

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Services/>,
    },{
      path: "/pc",
      element: <PC/>,
    },{
      path: "/contacts",
      element: <Contacts/>
    },{
      path: "/services/:id",
      element: <Service />
    }
  ]);

  return (
    <div className='flex-col flex max-w-full items-center w-dvw h-auto'>
      <Header />
      <RouterProvider router={router} />
      <Footer/>
    </div>
  )
}

export default App