import React from 'react'

const DefaultButton = () => {

  function redirect_form() {
    const get = window.location.href.split("/")
    if(get['3'] === "services"){
      window.location.replace('https://jurix.pro/#form?redirect=true')
    }else {
      window.location.replace('https://jurix.pro/#form?redirect=true')
      window.location.reload()
    }
  }

  return (
    <button onClick={() => redirect_form()} className='md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all'>
        Получить консультацию
    </button>
  )
}

export default DefaultButton