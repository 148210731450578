import Circle from '../../src/files/ServicesList/circle.png'
import { FaPhoneAlt, FaTelegramPlane, FaWhatsapp } from "react-icons/fa"

const Service = () => {

    const contact = [
        {
            title: <FaPhoneAlt size={26} color="#5956E9" />,
            contact: "8-800-222-4352",
            href: "tel:+78002224352",
            button: "Позвонить"
        },{
            title: <FaWhatsapp size={26} color="#28D146" />,
            contact: "7-929-773-4352",
            href: "https://wa.me/79297734352?text=Здравствуйте%2C+у+меня+есть+вопрос",
            button: "Написать"
        },{
            title: <FaTelegramPlane size={26} color="#5956E9" />,
            contact: "@jurix_pro",
            href: "https://telegram.me/jurix_pro",
            button: "Перейти"
        }
    ]

    const services = [
        {
            id:1,
            href: "zashita-prav-potrebitelei",
            name: "",
            description: "",
            description_text: (<></>)
        },
        {
            id:2,
            href: "podgotovka-i-analiz-documentov",
            name: "",
            description: "",
            description_text: (<></>)
        },
        {
            id:3,
            href: "zashita-prodavcov-na-marketplacah",
            name: "ЗАЩИТА ПРОДАВЦОВ НА МАРКЕТПЛЕЙСАХ.",
            description: "Вы продаете – мы защищаем Ваши интересы перед площадкой, поставщиками и потребителями. Возьмем на себя решение всех правовых проблем, проанализируем риски и предотвратим возможные нарушения. Мы успешно защищаем наших клиентов как на основных торговых площадках – Wildberries, Yandex.Market, Мегамаркет, Ozon, Авито, Aliexpress, так и на менее известных.",
            description_text: (<>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Оспаривание действий маркетплейсов.</strong><br/>
                Зачастую установленные торговыми площадками правила, требования и штрафы не соответствуют требованиям законодательства и могут быть отменены. Законодательство и судебная практика, а значит и положительный результат решения проблемы - в большинстве случаев на стороне наших клиентов.
                Мы проанализируем ситуацию и оспорим наложенные санкции и взыскания, проведем досудебную работу, аргументированно укажем на нарушения со стороны маркетплейсов в отношении продавца. При необходимости, проведем защиту клиента в суде до момента взыскания ущерба с торговой площадки.
                <br/><br/>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Защита от неправомерных действий правообладателей.</strong><br/>
                Злоупотребление правом на защиту интеллектуальной собственности – распространенная проблема у продавцов на маркетплейсах.
                Мы проанализируем жалобы владельцев торговых марок, оспорим незаконные требования правообладателей и их представителей, разъясним правовую основу принципа исчерпания исключительного права и злоупотребления правом, проведем переговоры с правообладателем, представителем правообладателя, уведомим о злоупотреблениях правообладателя торговую площадку.
                <br/><br/>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Защита от кражи авторского контента</strong><br/>
                Кража оригинальных и авторских решений для оформления карточки товара – одна из наиболее частых проблем продавцов.
                Мы проведем анализ рисков незаконного использования Ваших фотоматериалов и инфографики, подготовим необходимые действия по защите контента, сопроводим претензионную работу с нарушителями при случаях незаконного использования Вашего контента.
                <br/><br/>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Работа с покупателями.</strong><br/>
                Наши юристы возьмут на себе решение всех проблемных вопросов с покупателями по линии защиты прав потребителя. Мы обеспечим оперативную реакцию на претензии покупателей, подготовим аргументированные ответы в случае незаконности их требований для минимизации судебных исков и защиты от случаев «потребительского экстремизма», а в случае искового производства – осуществим квалифицированную защиту интересов продавца.
                <br/><br/>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Взыскание ущерба.</strong><br/>
                Одна из проблем маркетплейсов – утеря или повреждение находящегося на хранении площадки товаров продавца.
                Наши юристы помогут оценить величину ущерба, а также окажут полное сопровождение в вопросах взыскания такого ущерба с маркетплейсов – как в досудебном и претензионном порядке путем переговоров, так и путем взыскания через суд.
                <br/><br/>
                <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Свяжитесь с нами сейчас и доверьте сопровождение бизнеса профессионалам.</strong>
                </>)
        },
        {
            id:4,
            href: "uridicheskoe-coprovozdenie-biznesa",
            name: "ЮРИДИЧЕСКОЕ СОПРОВОЖДЕНИЕ БИЗНЕСА.",
            description: "Вы делаете бизнес – мы защищаем Ваши интересы. Берем на себя комплексное сопровождение всех возникающих юридических, бухгалтерских и кадровых вопросов бизнеса. Вместо штатного юриста – команда профессионалов в различных областях права, которая доступна всегда, не уходит в отпуск и не заболеет. ",
            description_text: (<>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Юридические консультации.</strong><br/>
            Оперативно и удобным способом дадим ответ на текущие вопросы правового характера - по телефону, email, мессенджерам, с предоставлением письменного заключения. Оценим Вашу проблему, предложим оптимальные варианты решения и, при необходимости, сразу приступим к работе.
            Наши юристы всегда на связи в течение дня и даже в выходные и праздники.<br/><br/>

            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Тендерное сопровождение.</strong><br/>
            Окажем комплексное сопровождение поставщиков по 44-ФЗ и 223-ФЗ. Наши специалисты проведут анализ закупочной документации и при необходимости подготовят запросы о разъяснениях, оценят обоснованность отклонения заявки и оспорят отклонение, проведут работу по линии ФАС и арбитража.
            Мы поможем в дальнейшем сопровождении исполнения контракта, в том числе с претензионной работой и ответами на претензии заказчика, при необходимости проведем досудебную работу по взысканию за неоплаченный контракт либо взыщем в судебном порядке.
            <br/><br/>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Экспертиза документов, разработка и анализ ЛНА.</strong><br/>
            Правильно оформленный договор или соглашение помогут защитить Ваши интересы при возникновении разногласий с контрагентами, уменьшат Ваши деловые риски, станут основой для претензионной или судебной защиты Ваших интересов при необходимости. Мы дадим правовую оценку Ваших договоров, соглашений, актов. Проанализируем риски клиента и предложим необходимые изменения в формулировках и содержании. Подготовим протоколы разногласий и защитим перед контрагентом Вашу позицию, предложим меры по приведению документации к требованиям законодательства.
            Также, подготовим с нуля или скорректируем уже имеющиеся локальные нормативные акты, предоставим рекомендации по организации документооборота, работе с хозяйственными, кадровыми и финансовыми документами. Проанализируем документооборот исходя из специфики бизнеса и индивидуальных запросов клиента, приведем документы в полное соответствие с требованиями законодательства.
            <br/><br/>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Кадровое и бухгалтерское сопровождение.</strong><br/>
            Возьмем на себя комплексное сопровождение бизнеса по линии налогового, бухгалтерского и кадрового учета. Проконтролируем все нюансы – от налоговой отчетности и начисления заработной платы до воинского учета и вопросов трудоустройства иностранных граждан. Защитим интересы бизнеса клиента перед государственными организациями и контролирующими органами, поможем избежать штрафов и взысканий, проведем проверку персонала на негатив.
            <br/><br/>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Досудебное урегулирование споров.</strong><br/>
            Большинство разногласий в бизнесе можно решить путем диалога и медиации.
            Наши юристы проанализируют сложившуюся ситуацию, предложат варианты урегулирования спора, подготовят мотивированные претензии контрагентам или направят аргументированный ответ на претензии партнеров и клиентов. Мы поможем Вам провести все необходимые действия для урегулирования споров путем переговоров и медиации для избежания длительных судебных процедур.
            <br/><br/>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Обеспечение безопасности бизнеса.</strong><br/>
            Должная осмотрительность предпринимателя – путь к минимизации рисков, как налоговых, так и в отношениях с контрагентами и персоналом.
            Наши специалисты проведут тщательную проверку Ваших контрагентов на негатив, оценят риски сотрудничества, возможность возникновения проблемных ситуаций как в отношениях с контрагентом, так и вытекающих из них – с налоговыми и другими государственными органами. Дадим оценку финансовым, юридическим и налоговым рискам.
            При необходимости проведем анализ персонала на негатив, укажем на возможные риски при сотрудничестве и поможем избежать потенциально проблемных сделок.
            Оценка контрагентов и персонала проводится по более чем 10 позициям с предоставлением аргументированного анализа.
            <br/><br/>
            <strong className="flex gap-[5px] items-center"><img src={Circle} className='min-w-[24px] min-h-[24px] mt-[3px]'/>Свяжитесь с нами сейчас и доверьте сопровождение бизнеса профессионалам.</strong>
            </>)
        },
        {
            id:5,
            href: "sdelki-s-nedvizemostiu",
            name: "",
            description: "",
            description_text: (<></>)
        },
        {
            id:6,
            href: "vziskanie-usherba",
            name: "",
            description: "",
            description_text: (<></>)
        },
        {
            id:7,
            href: "semeinie-spori",
            name: "",
            description: "",
            description_text: (<></>)
        },
        {
            id:8,
            href: "rabota-c-franshizami",
            name: "",
            description: "",
            description_text: (<></>)
        }
    ]

    const service = () => {
        const href =  window.location.href.split("/")
        const el = services.filter((el) => {
            if(el.href === href['4']){
                return el
            }
        })
        return el
    }

    const data = service()

  return (
    <div className="xl:pt-[146px] w-[100dvw] md:pt-[136px] pb-[50px] sm:pt-[92px] pt-[92px] px-2 sm:px-16 md:px-20 xl:px-40">
      <div className="w-[100%] pt-[20px] text-[24px] font-bold">
        {
            data['0'].name
        }
      </div>
      <div className="w-[100%] pt-[20px] text-[16px] font-bold">
        {
            data['0'].description
        }
      </div>
      <div className="w-[100%] pt-[20px] text-[16px]">
        {
            data['0'].description_text
        }<br/><br/>
      </div>
      <div className="pt-[50px] gap-[5px] flex-wrap max-h-max flex justify-center">
        {
            contact && contact.map((el, index) => {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <a href={el.href}>
                                <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                    {
                                        el.button
                                    }
                                </button>
                            </a>
                        </div>
                    )
                } else {
                    if(el.button === "Позвонить"){
                        return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                {
                                    el.button
                                }
                            </button>
                        </div>
                        )
                    }
                    return (
                        <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2 ">
                            <div className="font-[500]">
                                {
                                    el.title
                                }
                            </div>
                            <div className="font-[400]">
                                {
                                    el.contact
                                }
                            </div>
                            <a href={el.href}>
                                <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
                                    {
                                        el.button
                                    }
                                </button>
                            </a>
                        </div>
                    )
                }
            })
        }
      </div>
      {/* <div className="pt-[50px] gap-[5px] flex-wrap min-h-[57.3dvh] max-h-max pb-[50px] flex justify-center">
        {
            // contact && contact.map((el, index) => (
            //     <div key={index} className="max-w-[300px] h-max min-w-[200px] flex gap-[10px] flex-col items-center justify-center p-[20px] rounded-xl border-2">
            //         <div className="font-[500]">
            //             {
            //                 el.title
            //             }
            //         </div>
            //         <div className="font-[400]">
            //             {
            //                 el.contact
            //             }
            //         </div>
            //         <a href={el.href}>
            //             <button className="md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all">
            //                 Перейти
            //             </button>
            //         </a>
            //     </div>
            // ))
        }
      </div> */}
    </div>
  )
}

export default Service
