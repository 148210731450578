import React from 'react'
import Logo from '../../src/files/full-logo-white.svg'
import { FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
    const menu = [
        {
            id: 1,
            name: 'Главная',
            href: '#home'
        }, {
            id: 2,
            name: 'Услуги',
            href: '#services'
        }, {
            id: 3,
            name: 'Почему мы',
            href: '#why'
        }
    ]

    function redirect_menu(href) {
        window.location.href = href
    }
  return (
    <div className='bg-[#423FD9] text-white items-center justify-between flex lg:flex-row flex-col py-[50px] w-full px-2 sm:px-16 md:px-20 xl:px-40'>
        <img src={Logo} className='max-w-[120px]'/>
        <div className='lg:text-left text-right text-[14px] max-w-[250px]'>
            © 2024 «ЮРИКС». Все права защищены.<br/>
            Сайт разработан <a href="https://wa.me/79992244649" className='underline'>WEBSPACE</a>
        </div>  
        <div className='flex flex-col items-center gap-2'>
            <a href='tel:88002224352'>
                <div className='text-[32px] roboto-medium'>
                    8-800-222-4352
                </div>
            </a>
            <div className='text-[16px]'>
                info@jurix.pro
            </div>
            <div className='flex gap-1'>
                <a href="https://t.me/jurix_pro">
                    <FaTelegram size={28}/>
                </a>
                <a href="https://api.whatsapp.com/send?phone=79297734352">
                    <IoLogoWhatsapp size={28}/>
                </a>
            </div>
        </div> 
    </div>
  )
}

export default Footer