import React, { useState } from 'react'
import Logo from '../../../../src/files/full-logo.svg'
import DefaultButton from '../../../Buttons/DefaultButton'
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const MenuHeader = () => {

    const menu = [
        {
            id: 1,
            name: 'Главная',
            href: '#home'
        }, {
            id: 2,
            name: 'Услуги',
            href: '#services'
        }, {
            id: 3,
            name: 'Почему мы',
            href: '#why'
        }, {
            id: 3,
            name: 'Контакты',
            href: 'contacts'
        }
    ]

    const [open, setOpen] = useState(false)

    function redirect() {
        window.location.href = '/'
    }

    function redirect_menu(href) {
        window.location.href = `/${href}`
    }

    function renderMenuIcon() {
        if(!open) {
            return (
                <RxHamburgerMenu size={32}/>
            )
        }else {
            return (
                <RxCross1 size={32}/>
            )
        }
    }

    function openMenu() {
        if(!open) {
            setOpen(true)
        }else {
            setOpen(false)
        }
    }

    function style() {
        if(!open) {
            return {
                height: 0,
                overflow: 'hidden'
            }
        }else {
            return {
                minHeight: 200,
                overflow: 'hidden',
                padding: 20
            }
        }
    }

    function redirect_form() {
        const get = window.location.href.split("/")
        if(get['3'] === "services"){
          window.location.replace('https://jurix.pro/#form?redirect=true')
        }else {
          window.location.replace('https://jurix.pro/#form?redirect=true')
          window.location.reload()
        }
    }

  return (
    <>
        <div className='w-full h-[60px] z-[9] md:mt-0 mt-4 top-0 shadow-[0px_0px_8px_9px_rgba(34,60,80,0.18)] px-4 items-center rounded-md flex justify-between bg-white'>
            <div className='w-full h-full z-[9] flex relative justify-between items-center'>
                <ul className='2xl:flex hidden gap-[38px]'>
                    {
                        menu && menu.map((el,index) => (
                            <li key={index} onClick={() => redirect_menu(el.href)} className='text-xl roboto-regular hover:underline hover:underline-offset-2 transition-all duration-300 cursor-pointer hover:text-[#5956E9]'>
                                {
                                    el.name
                                }
                            </li>
                        ))
                    }
                </ul>
                <div className='2xl:hidden flex cursor-pointer' onClick={() => openMenu()}>
                    {
                        renderMenuIcon()
                    }
                </div>
                <div onClick={() => redirect()} className='absolute cursor-pointer flex items-center justify-center rounded-lg shadow-[0px_0px_8px_9px_rgba(34,60,80,0.18)] h-[90px] w-[200px] left-[50%] top-[50%] ml-[-100px] mt-[-45px] bg-white'>
                    <img src={Logo} className='w-[100px]'/>
                </div>
                <div className='lg:block hidden'>
                    {
                        <DefaultButton/>
                    }
                </div>
                <div onClick={() => redirect_form()} className='lg:hidden block cursor-pointer p-3 bg-button rounded-lg hover:scale-105 transition-all'>
                    <FaPhoneAlt color='white'/>
                </div>
            </div>
        </div>
        <div style={style()} className='bg-white z-[8] flex-col gap-3 shadow-[0px_0px_8px_9px_rgba(34,60,80,0.18)] 2xl:hidden transition-all rounded-md duration-500 mt-[-10px] min-h-max flex justify-center items-center'>
            <ul className='flex gap-5 mt-8 flex-wrap justify-center items-center'>
                {
                    menu && menu.map((el,index) => (
                        <li key={index} onClick={() => redirect_menu(el.href)} className='text-xl roboto-regular hover:underline hover:underline-offset-2 transition-all duration-300 cursor-pointer hover:text-[#5956E9]'>
                            {
                                el.name
                            }
                        </li>
                    ))
                }
            </ul>
            <div className='flex items-center shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] p-2 bg-white rounded-md gap-3'>
                <IoIosMail size={20}/>
                <div className='roboto-medium'>
                info@jurix.pro
                </div>
            </div>
            <a href='tel:88002224352'>
                <div className='flex items-center shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] p-2 bg-white rounded-md gap-3'>
                <FaPhoneAlt size={16}/>
                <div className='roboto-medium'>
                    8-800-222-4352
                </div>
                </div>
            </a>
        </div>
    </>
  )
}

export default MenuHeader