import React, { useState } from 'react'
import DefaultButton from '../Buttons/DefaultButton';
import InfoHeader from './Component/Info/Info.header';
import MenuHeader from './Component/Menu/Menu.header';

const Header = () => {
    return (
        <div className='w-full fixed bg-transparent z-[2] flex-col flex py-2 px-2 sm:px-16 md:px-20 xl:px-40'>
            <InfoHeader/>
            <MenuHeader/>
        </div>
    )
}

export default Header