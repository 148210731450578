import React, { useEffect } from 'react'
import PageInfo from './Component/PageInfo.js/PageInfo'
import BackGround from '../../src/files/BackGround.png'
import ServicesList from './Component/ServicesList.js/ServicesList'
import Form from './Component/Form/Form'
import Why from './Component/Why/Why'

const Services = () => {

  console.log(window.location.href)

  useEffect(() => {
    const get = window.location.href.split("?")

    if(get['1'] !== null){
      if(get['1'] === "redirect=true"){
        window.location.href = "#form"
      }
    }

  }, [window])
  
  return (
    <div className="w-full flex-col justify-start min-h-dvh relative xl:pt-[136px] md:pt-[126px] sm:pt-[82px] pt-[82px] items-center z-[1]">
      <div style={{
        backgroundImage: `url('${BackGround}')`
      }} className="w-full min-h-dvh 2xl:mt-[-136px] xl:mt-[-136px] md:mt-[-126px] lg:mt-[-126px] sm:mt-[-82px] mt-[-82px] flex items-start ">
        <PageInfo />
      </div>
      <div className='w-full'>
        <ServicesList/>
      </div>
      <div style={{
        backgroundImage: `url('${BackGround}')`
      }} className="w-full flex items-start">
        <Form />
      </div>
      <div className='w-full'>
        <Why/>
      </div>
      <div className="w-full flex justify-center items-start pb-[50px]">
        <div style={{width:560,height:800,overflow:"hidden",position:"relative",}}><iframe style={{width:"100%",height:"100%",border:"1px solid #e6e6e6",borderRadius:8,boxSizing:"border-box"}} src="https://yandex.ru/maps-reviews-widget/204170679262?comments"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Services