import axios from 'axios';
import React, { useState } from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaViber } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import InputMask from 'react-input-mask'

const Form = () => {

    const [name, setName] = useState('')

    const [tel, setTel] = useState('')
    
    const [contact, setContact] = useState('')
    
    const [message, setMessage] = useState('')

    const [error, setError] = useState('')
    const [access, setAccess] = useState(false)

    const [success ,setSuccess] = useState('')

    function send() {
        if(name === '' || contact === '' || tel === ''){
            setError('Не все поля заполнены!')
        }else if(access === false){
            setError('Согласитесь с политикой конф.!')
        }else {
            setError('')
            axios.post('https://form.jurix.pro', {
                name: name,
                tel:tel,
                contact: contact,
                message: message
            }).then(response => {
                setSuccess('Спасибо за обращение. Мы свяжемся с Вами в ближайшее время')
                setMessage('')
                setName('')
                setTel('')
                setTimeout(() => {
                    setSuccess('')
                }, 2000)
            })
        }
    }

    function renderError() {
        if(error === ''){
            return ''
        }else {
            return (
                <div className='w-full p-3 bg-red-600 text-white roboto-bold rounded-md'>
                    {
                        error
                    }
                </div>
            )
        }
    }

    function renderSuccess() {
        if(success === ''){
            return ''
        }else {
            return (
                <div className='w-full p-3 bg-[#5956E9] text-white roboto-bold rounded-md'>
                    {
                        success
                    }
                </div>
            )
        }
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return (
            <div className='flex justify-between lg:gap-0 gap-[20px] w-full items-center py-[120px] flex-col lg:flex-row px-2 sm:px-16 md:px-20 xl:px-40'>
                <div className='flex flex-col xl:max-w-[500px] max-w-[450px] gap-[20px]'>
                    <div className='text-white lg:text-left text-center roboto-bold text-[32px]'>
                        Нерешаемых проблем для нас не существует!
                    </div>
                    <div className='text-white lg:text-left text-center text-[16px]'>
                        Мы досконально изучаем проблему  и профессионально ведём только те дела, в которых имеется накопленный опыт и знания.
                        Мы максимально ответственно относимся к своим доверителям и делам. Дорожим своей репутацией и нацелены на долгосрочное развитие и сотрудничество.  
                    </div>
                    <div id="form" className='text-white lg:text-left text-center text-[16px]'>
                    Если у вас есть какой-то вопрос – оставляйте заявку прямо сейчас и получите БЕСПЛАТНУЮ консультацию максимально оперативно!
                    </div>
                </div>
                <div className='p-7 bg-white max-w-[400px] rounded-md flex flex-col items-center justify-between'>
                    {
                        renderError()
                    }
                    {
                        renderSuccess()
                    }
                    <div className='text-black text-center roboto-medium text-[18px]'>
                        Получите консультацию уже сегодня!
                    </div>
                    <div className='w-full text-[14px]'>
                        * - обязательны к заполнению
                    </div>
                    <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Имя*' className='w-full border-[#5956E9] border-b-2 p-2' />
                    <InputMask mask='7-(999)-999-99-99' className='mt-2 w-full border-[#5956E9] border-b-2 p-2' placeholder='Телефон*' value={tel} onChange={(e) => setTel(e.target.value)}></InputMask>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Сообщение' className='mt-2 w-full border-[#5956E9] border-b-2 p-2'/>
                    <div className='mt-2'>
                        Как с вами связаться?*
                    </div>
                    <div className='mt-2 flex gap-2 flex-col'>
                        <div className='flex gap-2'>
                            <input type="radio" id="contactChoice1" name="contact" value="whatsapp" onChange={(e) => setContact(e.target.value)}/>
                            <label htmlFor="contactChoice1" className='flex items-center gap-1'>WhatsApp<FaWhatsapp /></label>
                        </div>
                        <div className='flex gap-2'>
                            <input type="radio" id="contactChoice2" name="contact" value="viber" onChange={(e) => setContact(e.target.value)}/>
                            <label htmlFor="contactChoice2" className='flex items-center gap-1'>Viber<FaViber /></label>
                        </div>
                        <div className='flex gap-2'>
                            <input type="radio" id="contactChoice3" name="contact" value="telegram" onChange={(e) => setContact(e.target.value)}/>
                            <label htmlFor="contactChoice3" className='flex items-center gap-1'>Telegram<FaTelegram /></label>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <button onClick={() => send()} className='mt-3 md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all'>Получить консультацию</button>
                        <div className='text-[14px]'>
                            <input type="checkbox" value={access} onChange={(e) => setAccess(e.target.checked)}/>ознакомлен(-а) с <a href="/pc" className='underline'>Политикой конфиденциальности</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="form" className='flex justify-between lg:gap-0 gap-[20px] w-full items-center py-[120px] flex-col lg:flex-row px-2 sm:px-16 md:px-20 xl:px-40'>
            <div className='flex flex-col xl:max-w-[500px] max-w-[450px] gap-[20px]'>
                <div className='text-white lg:text-left text-center roboto-bold text-[32px]'>
                    Нерешаемых проблем для нас не существует!
                </div>
                <div className='text-white lg:text-left text-center text-[16px]'>
                    Мы досконально изучаем проблему  и профессионально ведём только те дела, в которых имеется накопленный опыт и знания.
                    Мы максимально ответственно относимся к своим доверителям и делам. Дорожим своей репутацией и нацелены на долгосрочное развитие и сотрудничество.  
                </div>
                <div className='text-white lg:text-left text-center text-[16px]'>
                Если у вас есть какой-то вопрос – оставляйте заявку прямо сейчас и получите БЕСПЛАТНУЮ консультацию максимально оперативно!
                </div>
            </div>
            <div className='p-7 bg-white max-w-[400px] rounded-md flex flex-col items-center justify-between'>
                {
                    renderError()
                }
                {
                    renderSuccess()
                }
                <div className='text-black text-center roboto-medium text-[18px]'>
                    Получите консультацию уже сегодня!
                </div>
                <div className='w-full text-[14px]'>
                    * - обязательны к заполнению
                </div>
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Имя*' className='w-full border-[#5956E9] border-b-2 p-2' />
                <InputMask mask='7-(999)-999-99-99' className='mt-2 w-full border-[#5956E9] border-b-2 p-2' placeholder='Телефон*' value={tel} onChange={(e) => setTel(e.target.value)}></InputMask>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Сообщение' className='mt-2 w-full border-[#5956E9] border-b-2 p-2'/>
                <div className='mt-2'>
                    Как с вами связаться?*
                </div>
                <div className='mt-2 flex gap-2 flex-col'>
                    <div className='flex gap-2'>
                        <input type="radio" id="contactChoice1" name="contact" value="whatsapp" onChange={(e) => setContact(e.target.value)}/>
                        <label htmlFor="contactChoice1" className='flex items-center gap-1'>WhatsApp<FaWhatsapp /></label>
                    </div>
                    <div className='flex gap-2'>
                        <input type="radio" id="contactChoice2" name="contact" value="viber" onChange={(e) => setContact(e.target.value)}/>
                        <label htmlFor="contactChoice2" className='flex items-center gap-1'>Viber<FaViber /></label>
                    </div>
                    <div className='flex gap-2'>
                        <input type="radio" id="contactChoice3" name="contact" value="telegram" onChange={(e) => setContact(e.target.value)}/>
                        <label htmlFor="contactChoice3" className='flex items-center gap-1'>Telegram<FaTelegram /></label>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <button onClick={() => send()} className='mt-3 md:py-2 md:px-3 border-none py-1 px-2 border rounded-md bg-button text-white roboto-medium hover:scale-105 transition-all'>Получить консультацию</button>
                    <div className='text-[14px]'>
                        <input type="checkbox" value={access} onChange={(e) => setAccess(e.target.checked)}/>ознакомлен(-а) с <a href="/pc" className='underline'>Политикой конфиденциальности</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form