import React from 'react'
import DefaultButton from '../../../../Component/Buttons/DefaultButton'
import Rectangle5 from '../../../../src/files/PageInfo/Rectangle5.png'
import Lawyer from '../../../../src/files/PageInfo/lawyer.svg'

const PageInfo = () => {
  return (
    <div id='home' className='flex lg:flex-row flex-col xl:pt-[136px] md:pt-[126px] sm:pt-[82px] pt-[82px] justify-center lg:justify-between items-center w-full min-h-dvh px-2 sm:px-16 md:px-20 xl:px-40'>
        <div className='lg:mt-0 mt-[30px] 2xl:w-[570px]  xl:w-[430px] relative bg-white justify-between flex flex-col p-8 rounded-md'>
            <div>
                {/* <h1 className=' roboto-bold text-[28px] lg:text-[30px] 2xl:text-[42px] xl:text-[37px] text-button'>
                    ЮРИКС - ЮРИДИЧЕСКАЯ КОМПАНИЯ
                </h1> */}
                <div className='2xl:mt-[18px] lg:mt-[10px] mt-[7px] xl:mt[12px]'>
                    Мы - опытная команда профессионалов в области права. Помогаем нашим клиентам в решении их проблем, приступая к работе с первой минуты - анализируем ситуацию, предлагаем варианты действий, сопровождаем клиента до момента решения проблемы. Если Вам нужна квалифицированная помощь в области административного, гражданского, семейного, жилищного или арбитражного права - оставьте заявку сейчас и мы предложим решение.
                </div>
            </div>
            <div className='mt-[20px]'>
                <div className='text-[#232233] mb-[10px] lg:text-[20px] xl:mb-[10px] 2xl:mb-[15px] roboto-medium xl:text-[25px]'>
                    ОСТАВЬТЕ ЗАЯВКУ СЕЙЧАС!
                </div>
                <DefaultButton />
            </div>
        </div>

        <img src={Lawyer} className='w-auto max-[340px]:max-w-[200px] lg:mt-0 mt-10 max-w-[250px] sm:max-w-[350px] lg:max-w-[450px] xl:max-w-[520px] 2xl:max-w-[620px]'/>
    </div>
  )
}

export default PageInfo