import { RxCross2 } from "react-icons/rx";

const Popup = ({services, setOpenPopup, openPopup}) => {

	return (
		<div className='w-full h-dvh z-[999] fixed top-0 left-0 bg-[#3A3A3A99]' onClick={() => setOpenPopup(null)}>
			{
				services && services.map((el, index) => {
					if(el.id === openPopup){
						return (
							<div key={index}  className='w-[500px] h-[650px] fixed top-[50%] left-[50%] z-[2000] ml-[-250px] mt-[-250px] p-5 rounded-md bg-white flex flex-col'>
								<div className='w-full flex justify-end h-auto font-bold'>
									<RxCross2 className='cursor-pointer' size={24} onClick={() => setOpenPopup(null)}/>
								</div>
								<div className='font-bold'>
									{
										el.open_name
									}
								</div>
								<div className='mt-[20px] overflow-y-auto'>
									{
										el.open_description
									}
								</div>
							</div>
						)
					}
				})
			}
		</div>
	)
}

export default Popup
