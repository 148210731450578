import React from 'react'
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";

const InfoHeader = () => {
  return (
    <div className='text-black md:flex relative mb-5 bg-transparent w-full hidden justify-between'>
      <div className='flex items-center z-[4] shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] p-2 bg-white rounded-md gap-3'>
        <IoIosMail size={20}/>
        <div className='roboto-medium'>
          info@jurix.pro
        </div>
      </div>
      <a href='tel:88002224352' className='z-[4] '>
        <div className='flex items-center shadow-[0px_0px_8px_3px_rgba(34,60,80,0.18)] p-2 bg-white rounded-md gap-3'>
          <FaPhoneAlt size={16}/>
          <div className='roboto-medium'>
            8-800-222-4352
          </div>
        </div>
      </a>
    </div>
  )
}

export default InfoHeader